import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import EmojiAll from "../components/Emoji/EmojiAll";
import config from "../../data/SiteConfig";
import "./decome.scss";

class DecomePage extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage decome">
          <Helmet title={`Emoji ♡ ${config.siteTitle}`} />
          <div>
            <h1>emojis</h1>
            <EmojiAll />
          </div>
        </div>
      </Layout>
    );
  }
}

export default DecomePage;